import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { useHistory } from "react-router-dom"
import {FaArrowCircleLeft} from 'react-icons/fa'
import {BiTrash} from 'react-icons/bi'
import moment from 'moment'

function ManageSessions() {

    let history = useHistory();

    const [latestSessions, setLatestSessions] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const getLatestSessions = async () => {
            const res = await Axios.get(`${process.env.REACT_APP_API_URL}/sessions/latest-session`, {
                signal
            });
            // console.log(res.data)
            if (res.status === 204) {
                setLatestSessions([])
            } else {
                setLatestSessions(res.data.data)
            }
        }
        getLatestSessions();
        return () => {
            abortController.abort();
        }
    }, []);

    
    
    const handleDelete = async (id) => {
        try {
           await Axios.delete(`${process.env.REACT_APP_API_URL}/sessions/delete/${id}`);
               
            const newData = latestSessions.filter(session => session.id !== id);
            setLatestSessions(newData);
        } catch (error) {
            console.log(error);  
        }
    }


    return (
        <div className="manage-session">
            <div className="upper-btn">
                <div className="row">
                    <div className="col">
                        <button onClick={() => history.push('/create-session')} className="btn btn-warning mb-2" type="button"><FaArrowCircleLeft /> Go To Create session</button>

                    </div>
                </div>

                <div className="table-container table-responsive" >
        <table className="table table-stripped table-hover table-bordered">
        <thead>
                        <tr>
                            <th scope="col">Next Session Start From</th>
                           
                            <th scope="col" colSpan="2">Action</th>
                        </tr>
                    </thead>
        {
           latestSessions && latestSessions.length > 0 ? latestSessions.map((session, index) =>{
               return (    
                    <tbody key={index}>
                        <tr>
                            {/* <th scope="row">{session.id}</th> */}
                            <td>{moment(session.nextSession).format('LL')}</td>
                            <td>
                            {/* <FiEdit style={{color: 'blue', fontSize: '25px', textAlign: 'center', cursor: 'pointer'}} onClick={() => handleEdit(session.id)} /> */}
                            <BiTrash style={{color: 'red', fontSize: '25px', textAlign: 'center', cursor: 'pointer'}} onClick={() => handleDelete(session.id)} />
                            </td>
                        </tr>
                    </tbody>
               )
           }) : <tbody >
                    <tr>
                        
                        <td scope="col" colSpan="6" className="text-center">Nothing in the database for sessions</td>
                    </tr>
                </tbody>
        }
        </table>

        </div>
            

               
            </div>
        </div>
    )
}

export default ManageSessions
