
import React from 'react'
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="news-events">
            <div className="solution_card">
            <h1 className="text-center">Dashboard</h1>

                <div className="solu_description">

                    <Link to="/dashboard/courses">
                        <button type="button" className="read_more_btn">
                                Courses Dashboard
                        </button>
                    </Link> 

                    <Link to="/dashboard/notices">
                        <button type="button" className="read_more_btn">
                                Notice Dashboard
                        </button>
                    </Link> 

                    <Link to="/dashboard/sliders">
                        <button type="button" className="read_more_btn">
                          Slider Dashboard
                        </button>
                    </Link> 
                    <Link to="/dashboard/news-events">
                        <button type="button" className="read_more_btn">
                        News & Events Dashboard
                        </button>
                    </Link> 
                    <Link to="/all-applications">
                        <button type="button" className="read_more_btn">
                                Application List
                        </button>
                    </Link> 
                    {/* <Link to="/dashboard/sessions">
                        <button type="button" className="read_more_btn">
                                Session
                        </button>
                    </Link> */}
                </div>
            </div>
        </div>
  )
}

export default Home
