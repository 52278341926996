import React from 'react'
import { Link } from 'react-router-dom'

function NoticeDashboard() {
    return (
        <div className="notices">
            <div className="solution_card">
                <div className="solu_description">

                    <Link to="/create-notice">
                        <button type="button" className="read_more_btn">
                                Create Notice
                        </button>
                    </Link> 

                    <Link to="/all-notices">
                        <button type="button" className="read_more_btn">
                                Manage Notices
                        </button>
                    </Link> 
                </div>
            </div>
        </div>
    )
}

export default NoticeDashboard
