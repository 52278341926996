import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, {useContext } from 'react';
import {Redirect} from 'react-router-dom'

import './App.css';
import Navbar from './Components/Navbar/Navbar'
import ManageApplications from './pages/ManageApplications'
import NewsEventsDashboard from './pages/NewsEventsDashboard'
import NoticeDashboard from './pages/NoticeDashboard'
import Home from './pages/Home'
import CreateNotice from './pages/CreateNotice'
import ManageNotice from './pages/ManageNotice';
import ManageNewsEvents from './pages/ManageNewsEvents';
import ManageSessions from './pages/ManageSessions';
import CreateNewsEvents from './pages/CreateNewsEvents';
import SliderDashboard from './pages/SliderDashboard';
import CreateSlider from './pages/CreateSlider';
import ManageSlider from './pages/ManageSlider';
import Footer from './Components/Footer/Footer';
import Login from './Components/Login/Login';
import { reducer, initialState } from '../src/reducers/AuthReducer'
import NotFound from './pages/NotFound';
import CourseDashboard from './pages/CourseDashboard';
import ManageCourse from './pages/ManageCourse';
import CreateCourse from './pages/CreateCourse';
import CreateSession from './pages/CreateSession';


import { AuthContext } from "./context/authContext/AuthContext";


function App() {
  const { token } = useContext(AuthContext);

  return (

      <Router>
      {!token ? <Login /> : <Redirect to="/dashboard"></Redirect>
}

    {token && <div>
            <Navbar />
            <Switch>
              <Route path="/" exact component={Login}  />
              <Route path="/dashboard" exact component={Home}  />

              {/* Courses Routes */}
              <Route path="/dashboard/courses"  component={CourseDashboard}  />
              <Route path="/create-course"  component={CreateCourse}  />
              <Route path="/all-courses"  component={ManageCourse}  />

              <Route path="/dashboard/sliders"  component={SliderDashboard}  />
              <Route path="/create-slider"  component={CreateSlider}  />
              <Route path="/all-sliders"  component={ManageSlider}  />
              <Route path="/all-applications"  component={ManageApplications} />
              <Route path="/dashboard/notices"  component={NoticeDashboard} />
              <Route path="/all-notices"  component={ManageNotice} />
              <Route path="/create-notice"   component={CreateNotice} />
              <Route path="/dashboard/news-events"   component={NewsEventsDashboard} />
              <Route path="/create-news-events"   component={CreateNewsEvents} />
              <Route path="/all-news-events"   component={ManageNewsEvents} />


              <Route path="/dashboard/sessions"  component={ManageSessions} />
              <Route path="/create-session"  component={CreateSession} />
              <Route component={NotFound} />

            </Switch> 
            <Footer />
        </div>}
        
        <ToastContainer />

      </Router>
  );
}

export default App;