import React, { useContext, useState } from "react";
import {AuthContext} from '../../context/authContext/AuthContext'
import { login } from "../../context/authContext/apiCalls";


function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
  
      const {isFetching, dispatch} = useContext(AuthContext)
  
  
  
      const handleLogin = (e) => {
          console.log(email, password)
          e.preventDefault();
          login({ email, password }, dispatch);
      }
  
    return (
        <div className="create-notice">
            <div className="form">
                <div className="title">Login</div>
                <div className="input-container ic1">
                    <input id="email" name="email" value={email}  onChange={(e) => setEmail(e.target.value)} className="input" type="email" placeholder=" " />
                    <div className="cut cut-short"></div>
                    <label htmlFor="email" className="placeholder">Email</label>
                </div>
                <div className="input-container ic2">
                    <input id="password" name="password" value={password}  onChange={(e) => setPassword(e.target.value)} className="input" type="password" placeholder=" " />
                    <div className="cut cut-short"></div>
                    <label htmlFor="password" className="placeholder">Password</label>
                </div>
                
                <button type="submit"   disabled={isFetching}
 onClick={handleLogin} className="submit">Login</button>
            </div>
        </div>
    )
}

export default Login


