import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { useHistory } from "react-router-dom"
import {FaArrowCircleLeft} from 'react-icons/fa'
import {BiTrash} from 'react-icons/bi'

function ManageSlider() {
    let history = useHistory();

    const [allSlider, setAllSlider] = useState([]);
    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const getAllSliders = async () => {
            const res = await Axios.get(`${process.env.REACT_APP_API_URL}/sliders`, {
                signal
            });
            console.log(res.data.data)
            if (res.status === 404) {
                setAllSlider([])
            } else {
                setAllSlider(res.data.data)
            }
        }
        getAllSliders();
        return () => {
            abortController.abort();
        }
    }, []);
    // console.log(allSlider)

    
    
    const handleDelete = async (id) => {
        try {
            await Axios.delete(`${process.env.REACT_APP_API_URL}/sliders/delete-slider/${id}`);
               
            const newData = allSlider.filter(slider => slider.id !== id);
            setAllSlider(newData);
        } catch (error) {
            console.log(error);  
        }
    }

    return (
        <div className="manage-notice">
            <div className="upper-btn">
                <div className="row">
                    <div className="col">
                        <button onClick={() => history.push('/create-slider')} className="btn btn-warning mb-2" type="button"><FaArrowCircleLeft /> Go To Create Slider</button>

                    </div>
                </div>

                <div className="table-container" >
        <table className="table table-stripped table-hover table-bordered">
        <thead>
                        <tr>
                            <th scope="col">Slider Header</th>
                            <th scope="col">Slider Description</th>
                            <th scope="col">Slider Sequence</th>
                            <th scope="col">Slider Image</th>
                           
                            <th scope="col" colSpan="2">Action</th>
                        </tr>
                    </thead>
        {
           allSlider && allSlider.length > 0 ? allSlider.map((slider, index) =>{
               return (    
                    <tbody key={index}>
                        <tr>
                            {/* <th scope="row">{slider.id}</th> */}
                            <td>{slider.image_header}</td>
                            <td>{slider.image_description}</td>
                            <td>{slider.sequence}</td>
                            <td>{slider.image}</td>
                            <td><BiTrash style={{color: 'red', fontSize: '25px', textAlign: 'center', cursor: 'pointer'}} onClick={() => handleDelete(slider.id)} /></td>
                        </tr>
                    </tbody>
               )
           }) : <tbody >
                    <tr>
                        
                        <td scope="col" colSpan="6" className="text-center">Nothing in the database for sliders</td>
                    </tr>
                </tbody>
        }
        </table>

        </div>
            
            </div>
        </div>
    )
}

export default ManageSlider
