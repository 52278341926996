import React from 'react'
import { useState } from 'react'
import { useHistory } from "react-router-dom"
import Axios from 'axios'
import {toast} from 'react-toastify'
import {FaArrowCircleLeft} from 'react-icons/fa'
import { Editor } from '@tinymce/tinymce-react';


function CreateCourse() {
    let history = useHistory();

    const [courseInfo, setCourseInfo] = useState({
        name: '',
        sequence: '',
        // isActive: '',
        individualFee: '',
        batchFee: '',
        aboutCourse: ''
    })


    const [selectedFile, setSelectedFile] = useState(null);
    const handleEditorChange = (content, editor) => {
        courseInfo.aboutCourse = content;
    }

    const handleChange = (e) => {
        const { value, name } = e.target
        setCourseInfo(prevValue => {
            return({
                ...prevValue,
                [name]: value
            })
        });
    }

    const hanldeUpload = (e) => {
        setSelectedFile(e.target.files[0]);
    }


    const handleSave = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", courseInfo.name);
        formData.append("sequence", courseInfo.sequence);
        // formData.append("isActive", courseInfo.isActive);
        formData.append("individualFee", courseInfo.individualFee);
        formData.append("batchFee", courseInfo.batchFee);
        formData.append("aboutCourse", courseInfo.aboutCourse);
        formData.append("image", selectedFile);

        
        try {  
            const res = await Axios.post(`${process.env.REACT_APP_API_URL}/courses/create-course`,formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            // console.log(res)
            setCourseInfo({
                name: '',
                sequence: '',
                // isActive: '',
                individualFee: '',
                batchFee: '',
                aboutCourse: ''
            })

            // setSelectedFile("");
            toast.success(res.data.message, {
                className: "success-toast",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            history.push('/all-courses')
        } catch (error) {
            console.log(error.response);
            setCourseInfo({
                name: '',
                sequence: '',
                // isActive: '',
                individualFee: '',
                batchFee: '',
                aboutCourse: ''
            })
            setSelectedFile(null)
            toast.error(error.response.data.message, {
                className: "error-toast",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
        }
    }

   

    const handleReset = (e) => {
        e.preventDefault();
        setCourseInfo({
            name: '',
            sequence: '',
            // isActive: '',
            individualFee: '',
            batchFee: '',
            aboutCourse: ''
        })
    }

    return (
        <div className="create-course">
        
                <div className="course-form">
                <div className="row">
                    <div className="col">
                        <button onClick={() => history.push('/all-courses')} className="btn btn-warning mb-2" type="button"><FaArrowCircleLeft />Go To All Course</button>

                    </div>
                </div>
                    <div className="name">Create Course</div>
                    <div className="input-container">
                        <input value={courseInfo.name} onChange={handleChange}  id="name" className="input" name="name" type="text" placeholder=" " />
                        <div className="cut"></div>
                        <label htmlFor="name" className="placeholder">Course Name</label>
                    </div>


                    <div className="ic1">
                        <Editor
                            initialValue="<p>N.B:First write course details and then fill up other input fields.</p>"
                            id="content"
                            apiKey="7bm3tl8lrhz9fp2eauqbi2cbyfvmsvg6bws6wzf85bflo3uv"
                            value={courseInfo.aboutCourse}
                            init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat | help'
                            }}
                            onEditorChange={handleEditorChange}
                        />
                    </div>

                    <div className="input-container ic1">
                        <input value={courseInfo.sequence} onChange={handleChange}  id="sequence" className="input" name="sequence" min="1" type="number" placeholder=" " />
                        <div className="cut"></div>
                        <label htmlFor="sequence" className="placeholder">Course Sequence</label>
                    </div>


                    <div className="input-container ic1">
                        <input value={courseInfo.individualFee} onChange={handleChange}  id="individualFee" className="input" name="individualFee" min="1" type="number" placeholder=" " />
                        <div className="cut"></div>
                        <label htmlFor="individualFee" className="placeholder">Course individualFee</label>
                    </div>

                    <div className="input-container ic1">
                        <input value={courseInfo.batchFee} onChange={handleChange}  id="batchFee" className="input" name="batchFee" min="1" type="number" placeholder=" " />
                        <div className="cut"></div>
                        <label htmlFor="batchFee" className="placeholder">Course batchFee</label>
                    </div>

                   
                    <div className="input-container ic2">
                        <input  onChange={hanldeUpload} id="image" className="input" name="image" accept=".jpg,.png" type="file" placeholder=" " />
                        <div className="cut"></div>
                        <label htmlFor="image" className="placeholder">File (.jpg, .png, .jpeg) (Max 3MB)</label>
                    </div>
                    
                    <div className="row">
                        <div className="col">
                            <button type="submit" onClick={handleSave} className="submit">Save</button>
                        </div>
                        <div className="col">
                            <button type="reset" onClick={handleReset} className="submit btn-warning">Reset</button>
                        </div>
                    </div>
                   
                    
                </div>
        </div>
    )
}

export default CreateCourse
