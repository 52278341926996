import React, {useState} from 'react'
import {toast} from 'react-toastify'
import Axios from 'axios'
import { useHistory } from "react-router-dom";
import { FaArrowCircleLeft } from "react-icons/fa";
import { Editor } from '@tinymce/tinymce-react';


function CreateNotice() {
    let history = useHistory();

    const [noticeInfo, setNoticeInfo] = useState({
        title: '',
        noticeDetails: ''
    })

    const [selectedFile, setSelectedFile] = useState(null);

    const handleChange = (e) => {
        const { value, name } = e.target
        setNoticeInfo(prevValue => {
            return({
                ...prevValue,
                [name]: value
            })
        });
    }

    const handleEditorChange = (content, editor) => {
        noticeInfo.noticeDetails = content;
    }

    const hanldeUpload = (e) => {
        setSelectedFile(e.target.files[0]);
    }


    const handleSave = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", noticeInfo.title);
        formData.append("files", selectedFile);
        formData.append("noticeDetails", noticeInfo.noticeDetails);

        
        try {  
            const res = await Axios.post(`${process.env.REACT_APP_API_URL}/notices/create-notice`,formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            // console.log(res)
            setNoticeInfo({
                title: '',
                noticeDetails: ''
            })

            // setSelectedFile("");
            toast.success(res.data.message, {
                className: "success-toast",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            history.push('/all-notices')
        } catch (error) {
            console.log(error.response);
            setNoticeInfo({
                title: '',
                noticeDetails: ''
            })
            setSelectedFile(null)
            toast.error(error.response.data.message, {
                className: "error-toast",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
        }
    }


    const handleReset = (e) => {
        e.preventDefault();
        setNoticeInfo({
            title: '',
            noticeDetails: ''
        })
    }


    return (
        <div className="create-notice">
                <div className="news-form">
                <div className="row">
          <div className="col">
            <button
              onClick={() => history.push("/all-notices")}
              className="btn btn-warning mb-2"
              type="button"
            >
              <FaArrowCircleLeft />
              Go To All Notices
            </button>
          </div>
        </div>
                    <div className="title">Create Notice</div>
                    <div className="input-container ic1">
                        <input value={noticeInfo.title} onChange={handleChange}  id="title" className="input" name="title" type="text" placeholder=" " />
                        <div className="cut"></div>
                        <label htmlFor="title" className="placeholder">Title</label>
                    </div>
                    <div className="input-container ic2">
                        <input  onChange={hanldeUpload} id="files" className="input" name="files" accept=".jpg,.png, .pdf" type="file" placeholder=" " />
                        <div className="cut"></div>
                        <label htmlFor="files" className="placeholder">File (.jpg, .png, .pdf) (Max 3MB)</label>
                    </div>

                    <div className="ic1">
                        <Editor
                            initialValue="<p>N.B:First write notice details then put a title and image (mandatory)</p>"
                            id="noticeDetails"
                            apiKey="7bm3tl8lrhz9fp2eauqbi2cbyfvmsvg6bws6wzf85bflo3uv"
                            value={noticeInfo.noticeDetails}
                            init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat | help |table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
                            }}
                            onEditorChange={handleEditorChange}
                        />
                    </div>
                    
                    <div className="row">
                        <div className="col">
                            <button type="submit" onClick={handleSave} className="submit">Save</button>
                        </div>
                        <div className="col">
                            <button type="reset" onClick={handleReset} className="submit btn-warning">Reset</button>
                        </div>
                    </div>
                   
                    
                </div>
        </div>
    )
}

export default CreateNotice
