import React from 'react'
import { Link } from 'react-router-dom'

function CourseDashboard() {
    return (
        <div className="notices">
            <div className="solution_card">
                <div className="solu_description">

                    <Link to="/create-course">
                        <button type="button" className="read_more_btn">
                                Create Course
                        </button>
                    </Link> 

                    <Link to="/all-courses">
                        <button type="button" className="read_more_btn">
                                Manage Courses
                        </button>
                    </Link> 
                </div>
            </div>
        </div>
    )
}

export default CourseDashboard
