//import useState hook to create menu collapse state
import React, { useState } from "react";

//import icons from react icons
import { FaBars } from "react-icons/fa";

import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";

import {SideBarData} from './SideBarData'

import "./Navbar.css";
import { useHistory,Redirect } from "react-router-dom";

const Navbar = () => {
  
    //create initial menuCollapse state using useState hook
    const [menuCollapse, setMenuCollapse] = useState(false)
    const [showBar, setShowBar] = useState(true);

    const contentStyle = {
      marginLeft: menuCollapse ? "250px" : "70px", // arbitrary values
      transition: "margin 0.2s ease"
  };

    //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };


  let history = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    history.push('/');
    setShowBar(false);
    // <Redirect to='/' />
  }

  return (
    <>
    {showBar && 
    <IconContext.Provider value={{color: '#F4CA29'}}>
      <div id="navbar">
          <Link to="#" className="menu-bars">
            <FaBars onClick={menuIconClick} />
          </Link> 
          <nav className={menuCollapse ? 'nav-menu active' : 'nav-menu' }>
              <ul className="nav-menu-items" onClick={menuIconClick}>
                  <li className="navbar-toggle">
                     <Link to="/" className="menu-bars">
                       <AiOutlineClose />
                     </Link>
                  </li>

                  {
                    SideBarData.map((item, index) => {
                      return (
                        <li key={index} className={item.cName}>
                          <Link  to={item.path}>{item.icon}<span>{item.title}</span></Link>
                        </li>
                      )
                    })
                  }

                  <li className="navbar-toggle">
                     <button onClick={handleLogout} className="btn btn-danger btn-lg">Logout</button>
                  </li>
              </ul>
          </nav>    
      </div>
      </IconContext.Provider>
    } 
    </>
  );
};

export default Navbar;