export const loginStart = () => ({
    type: 'LOGIN_START',
})

export const loginSuccess = (token) => ({
    type: 'LOGIN_SUCCESS',
    payload: token,
})


export const loginFailure = () => ({
    type: 'LOGIN_FAIL',
})



export const logout = () => ({
    type: 'LOGOUT',
})