import React from 'react'
import { Link } from 'react-router-dom'

function SliderDashboard() {
    return (
        <div className="notices">
            <div className="solution_card">
                <div className="solu_description">

                    <Link to="/create-slider">
                        <button type="button" className="read_more_btn">
                                Upload Slider
                        </button>
                    </Link> 

                    <Link to="/all-sliders">
                        <button type="button" className="read_more_btn">
                                Manage Sliders
                        </button>
                    </Link> 
                </div>
            </div>
        </div>
    )
}

export default SliderDashboard
