import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { useHistory } from "react-router-dom"
import {FaArrowCircleLeft} from 'react-icons/fa'
import {FiEdit} from 'react-icons/fi'
import {BiTrash} from 'react-icons/bi'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search  } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator'
import {Modal} from 'react-bootstrap'

function ManageCourse() {
    const { ExportCSVButton } = CSVExport;
    const { SearchBar } = Search;

    let history = useHistory();

    const [allCourses, setAllCourses] = useState([]);

    const columns = [
        {dataField: 'name', text: 'Course Name'},
        {dataField: 'image', text: 'Course Image'},
        {dataField: 'sequence', text: 'Course Sequence'},
        {dataField: 'isActive', text: 'Is Active Course'},
        {dataField: 'individualFee', text: 'Individual Fee'},
        {dataField: 'batchFee', text: 'Batch Fee'},
        {dataField: 'enrolled', text: 'Total Enrolled'},
    ]

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const getAllCourses = async () => {
            const res = await Axios.get(`${process.env.REACT_APP_API_URL}/courses/list`, {
                signal
            });
            console.log(res.data)
            if (res.status === 204) {
                setAllCourses([])
            } else {
                setAllCourses(res.data.data)
            }
        }
        getAllCourses();
        return () => {
            abortController.abort();
        }
    }, []);
    // console.log(allCourses)

    
    
    const handleDelete = async (id) => {
        try {
           await Axios.delete(`${process.env.REACT_APP_API_URL}/courses/delete-course/${id}`);
               
            const newData = allCourses.filter(course => course.id !== id);
            setAllCourses(newData);
        } catch (error) {
            console.log(error);  
        }
    }


    const handleEdit = async (id) => {
        try {
           await Axios.delete(`${process.env.REACT_APP_API_URL}/notices/update-course/${id}`);
               
            const newData = allCourses.filter(course => course.id !== id);
            setAllCourses(newData);
        } catch (error) {
            console.log(error);  
        }
    }

    return (
        <div className="manage-course">
            <div className="upper-btn">
                <div className="row">
                    <div className="col">
                        <button onClick={() => history.push('/create-course')} className="btn btn-warning mb-2" type="button"><FaArrowCircleLeft /> Go To Create course</button>

                    </div>
                </div>

                <div className="table-container table-responsive" >
        <table className="table table-stripped table-hover table-bordered">
        <thead>
                        <tr>
                            <th scope="col">Course Name</th>
                            <th scope="col">Course Image</th>
                            <th scope="col">Course Sequence</th>
                            <th scope="col">Is Active Course</th>
                            <th scope="col">Individual Fee</th>
                            <th scope="col">Batch Fee</th>
                            <th scope="col">Total Enrolled</th>
                           
                            <th scope="col" colSpan="2">Action</th>
                        </tr>
                    </thead>
        {
           allCourses && allCourses.length > 0 ? allCourses.map((course, index) =>{
               return (    
                    <tbody key={index}>
                        <tr>
                            {/* <th scope="row">{course.id}</th> */}
                            <td>{course.name}</td>
                            <td>{course.image}</td>
                            <td>{course.sequence}</td>
                            <td>{course.isActive ? "Yes" : "No" }</td>
                            <td>{course.individualFee}</td>
                            <td>{course.batchFee}</td>
                            <td>{course.enrolled}</td>
                            <td>
                            {/* <FiEdit style={{color: 'blue', fontSize: '25px', textAlign: 'center', cursor: 'pointer'}} onClick={() => handleEdit(course.id)} /> */}
                            <BiTrash style={{color: 'red', fontSize: '25px', textAlign: 'center', cursor: 'pointer'}} onClick={() => handleDelete(course.id)} />
                            </td>
                        </tr>
                    </tbody>
               )
           }) : <tbody >
                    <tr>
                        
                        <td scope="col" colSpan="6" className="text-center">Nothing in the database for notices</td>
                    </tr>
                </tbody>
        }
        </table>

        </div>
            

               
            </div>
        </div>
    )
}

export default ManageCourse
