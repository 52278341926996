import React from 'react'
import { Link } from 'react-router-dom'

function NewsEventsDashboard() {
    return (
        <div className="news-events">
            <div className="solution_card">
                <div className="solu_description">

                    <Link to="/create-news-events">
                        <button type="button" className="read_more_btn">
                                Create News & Events
                        </button>
                    </Link> 

                    <Link to="/all-news-events">
                        <button type="button" className="read_more_btn">
                                Manage News & Events
                        </button>
                    </Link> 
                </div>
            </div>
        </div>
    )
}

export default NewsEventsDashboard
