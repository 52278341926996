import React from 'react'
import { FaHome, FaBook, FaNewspaper } from "react-icons/fa";
import { BiChalkboard, BiTask, BiLogOut } from "react-icons/bi";

export const SideBarData = [
    {
        title: 'Home',
        path: '/dashboard',
        icon: <FaHome />,
        cName: 'nav-text'
    },
    {
        title: 'Course',
        path: '/dashboard/courses',
        icon: <FaBook />,
        cName: 'nav-text'
    },
    {
        title: 'Notice',
        path: '/dashboard/notices',
        icon: <BiChalkboard />,
        cName: 'nav-text'
    },
    {
        title: 'Slider',
        path: '/dashboard/sliders',
        icon: <FaBook />,
        cName: 'nav-text'
    },
    {
        title: 'News Events',
        path: '/dashboard/news-events',
        icon: <FaNewspaper />,
        cName: 'nav-text'
    },
    {
        title: 'All Application',
        path: '/all-applications',
        icon: <BiTask />,
        cName: 'nav-text'
    }
    // },
    // {
    //     title: 'Logout',
    //     path: '/logout',
    //     icon: <BiLogOut />,
    //     cName: 'nav-text'
    // }
]
