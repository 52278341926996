import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { useHistory } from "react-router-dom"
import {FaArrowCircleLeft} from 'react-icons/fa'
import {BiTrash} from 'react-icons/bi'

function ManageNewsEvents() {
    let history = useHistory();

    const [allNews, setAllNews] = useState([]);
    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const getAllNews = async () => {
            const res = await Axios.get(`${process.env.REACT_APP_API_URL}/newsevents`, {
                signal
            });
            // console.log(res.data.data)
            if (res.status === 404) {
                setAllNews([])
            } else {
                setAllNews(res.data.data)
            }
        }
        getAllNews();
        return () => {
            abortController.abort();
        }
    }, []);
    // console.log(allNews)

    
    
    const handleDelete = async (id) => {
        try {
            await Axios.delete(`${process.env.REACT_APP_API_URL}/newsevents/delete-news-events/${id}`);
            // console.log(res.data);
               
            const newData = allNews.filter(news => news.id !== id);
            setAllNews(newData);
        } catch (error) {
            console.log(error);  
        }
    }


    return (
        <div className="manage-notice">
            <div className="upper-btn">
                <div className="row">
                    <div className="col">
                        <button onClick={() => history.push('/create-news-events')} className="btn btn-warning mb-2" type="button"><FaArrowCircleLeft /> Go To Create News</button>

                    </div>
                </div>

                <div className="table-container" >
        <table className="table table-stripped table-hover table-bordered">
        <thead>
                        <tr>
                            <th scope="col">News Title</th>
                            <th scope="col">News Image</th>
                           
                            <th scope="col" colSpan="2">Action</th>
                        </tr>
                    </thead>
        {
           allNews && allNews.length > 0 ? allNews.map((news, index) =>{
               return (    
                    <tbody key={index}>
                        <tr>
                            <td>{news.title}</td>
                            <td>{news.image}</td>
                            <td><BiTrash style={{color: 'red', fontSize: '25px', textAlign: 'center', cursor: 'pointer'}} onClick={() => handleDelete(news.id)} /></td>
                        </tr>
                    </tbody>
               )
           }) : <tbody >
                    <tr>
                        
                        <td scope="col" colSpan="6" className="text-center">Nothing in the database for news</td>
                    </tr>
                </tbody>
        }
        </table>

        </div>
            
            </div>
        </div>
    )
}

export default ManageNewsEvents
