import React, { useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FaArrowCircleLeft } from "react-icons/fa";

function CreateSlider() {
  let history = useHistory();

  const [sliderInfo, setSliderInfo] = useState({
    image_header: "",
    image_description: "",
    sequence: ""
  });

  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setSliderInfo((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const hanldeUpload = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image_header", sliderInfo.image_header);
    formData.append("image_description", sliderInfo.image_description);
    formData.append("sequence", sliderInfo.sequence);
    formData.append("image", selectedFile);

    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/sliders/create-slider`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(res)
      setSliderInfo({
        image_header: "",
        image_description: "",
        sequence: "",
      });

      // setSelectedFile("");
      toast.success(res.data.message, {
        className: "success-toast",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      history.push("/all-sliders");
    } catch (error) {
      console.log(error.response);
      setSliderInfo({
        image_header: "",
        image_description: "",
        sequence: "",
      });
      setSelectedFile(null);
      toast.error(error.response.data.message, {
        className: "error-toast",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setSliderInfo({
        image_header: "",
        image_description: "",
        sequence: ""
    });
  };

  return (
    <div className="create-notice">
      <div className="news-form">
        <div className="row">
          <div className="col">
            <button
              onClick={() => history.push("/all-slider")}
              className="btn btn-warning mb-2"
              type="button"
            >
              <FaArrowCircleLeft />
              Go To All Sliders
            </button>
          </div>
        </div>
        <div className="title">Upload Slider</div>
        <div className="input-container ic1">
          <input id="image_header" name="image_header" value={sliderInfo.image_header} onChange={handleChange}  maxLength="90" className="input" type="text" placeholder=" " />
          <div className="cut"></div>
          <label htmlFor="image_header" className="placeholder">
            Image Header
          </label>
        </div>
        <div className="input-container ic2">
          <input id="image_description" name="image_description" value={sliderInfo.image_description} maxLength="120" className="input"  onChange={handleChange}  type="text" placeholder=" " />
          <div className="cut"></div>
          <label htmlFor="image_description" className="placeholder">
            Image Description
          </label>
        </div>
        <div className="input-container ic2">
          <input
            id="sequence"
            className="input"
            min="1"
            name="sequence"
            onChange={handleChange}
            value={sliderInfo.sequence}
            type="number"
            placeholder=" "
          />
          <div className="cut"></div>
          <label htmlFor="sequence" className="placeholder">
            Sequence
          </label>
        </div>
        <div className="input-container ic2">
          <input
            onChange={hanldeUpload}
            id="image"
            className="input"
            name="image"
            accept=".jpg, .png, .jpeg"
            type="file"
            placeholder=" "
          />
          <div className="cut"></div>
          <label htmlFor="image" className="placeholder">
            File (.jpg, .png, .jpeg) (Max 3MB)
          </label>
        </div>
        <div className="row">
          <div className="col">
            <button type="submit" onClick={handleSave} className="submit">
              Save
            </button>
          </div>
          <div className="col">
            <button
              type="reset"
              onClick={handleReset}
              className="submit btn-warning"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateSlider;
