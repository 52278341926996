import React, {useState} from 'react'
import {toast} from 'react-toastify'
import Axios from 'axios'
import { useHistory } from "react-router-dom";
import { FaArrowCircleLeft } from "react-icons/fa";

function CreateSession() {
    let history = useHistory();

    const [sessionInfo, setSessionInfo] = useState({
        nextSession: ''
    })


    const handleChange = (e) => {
        const { value, name } = e.target
        setSessionInfo(prevValue => {
            return({
                ...prevValue,
                [name]: value
            })
        });
    }

    const handleSave = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("nextSession", sessionInfo.nextSession);
        
        try {  
            const res = await Axios.post(`${process.env.REACT_APP_API_URL}/sessions/add`,formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            console.log(res)
            setSessionInfo({
                nextSession: ''
            })

            toast.success(res.data.message, {
                className: "success-toast",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            history.push('/')
        } catch (error) {
            console.log(error.response);
            setSessionInfo({
                nextSession: ''
            })
            toast.error(error.response.data.message, {
                className: "error-toast",
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
        }
    }


    const handleReset = (e) => {
        e.preventDefault();
        setSessionInfo({
            nextSession: ''
        })
    }


    return (
        <div className="create-notice">
                <div className="form">
                <div className="row">
          <div className="col">
            <button
              onClick={() => history.push("/")}
              className="btn btn-warning mb-2"
              type="button"
            >
              <FaArrowCircleLeft />
              Go To Home
            </button>
          </div>
        </div>
                    <div className="title">Create Session</div>
                    <div className="input-container ic1">
                        <input value={sessionInfo.nextSession} onChange={handleChange}  id="nextSession" className="input" name="nextSession" type="date" placeholder=" " />
                        <div className="cut"></div>
                        <label htmlFor="nextSession" className="placeholder">nextSession</label>
                    </div>
                    
                    <div className="row">
                        <div className="col">
                            <button type="submit" onClick={handleSave} className="submit">Save</button>
                        </div>
                        <div className="col">
                            <button type="reset" onClick={handleReset} className="submit btn-warning">Reset</button>
                        </div>
                    </div>
                   
                    
                </div>
        </div>
    )
}

export default CreateSession
