import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="container bottom_border">
          <div className="row">
            <div className="col">
              <p className="mb10">
                &copy; SoftShore Technologies
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
