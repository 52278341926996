import React from 'react'
import {Link } from 'react-router-dom'
import {ImHome} from 'react-icons/im'
function NotFound() {
    return (
        <div className="manage-notice">
        <div className="error-area">
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="error-content">
                        <img style={{width: '500px'}} src="/404.png" alt="error" />
    
                        <h3>Page Not Found</h3>
                        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                        <Link  to="/">
                        <button className="btn btn-primary">

                        <ImHome />Go To Home
                        </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    )
}

export default NotFound
