import { createContext, useReducer, useEffect } from 'react'
import AuthReducer from './AuthReducer'


const INITIAL_STATE = {
    token: JSON.parse(sessionStorage.getItem('token')) || null,
    isFetching: false,
    error: false
}


export const AuthContext = createContext(INITIAL_STATE)

export const AuthContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE)

    useEffect(() => {
        localStorage.setItem('token', JSON.stringify(state.token));
    },[state.token])

    return (
        <AuthContext.Provider value={{token: state.token, isFetching: state.isFetching, error: state.error, dispatch}} >
            {children}
        </AuthContext.Provider>
    )
}
