import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { useHistory } from "react-router-dom"
import {FaArrowCircleLeft} from 'react-icons/fa'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search  } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator'




function ManageApplications() {
    let history = useHistory();
    const { ExportCSVButton } = CSVExport;
    const { SearchBar } = Search;

    const columns = [
        {dataField: 'applicationSerial', text: 'Application Serial'},
        {dataField: 'applicantName', text: 'Applicant Name'},
        // {dataField: 'applicantAge', text: 'Applicant Age'},
        {dataField: 'dob', text: 'Applicant DOB'},
        {dataField: 'fatherName', text: 'Father Name'},
        {dataField: 'motherName', text: 'Mother Name'},
        {dataField: 'applicantMobile', text: 'Applicant Mobile'},
        // {dataField: 'applicantEmail', text: 'Applicant Email'},
        // {dataField: 'applicantAddress', text: 'Applicant Address'},
        {dataField: 'appliedCourse', text: 'Applied Course'},
        // {dataField: 'courseDuration', text: 'Course Duration'},    
        {dataField: 'classTime', text: 'Class Time'},    
        {dataField: 'teachingType', text: 'Study Type'},    
        {dataField: 'paymentMethod', text: 'Payment Method'},    
        {dataField: 'paymentTxnId', text: 'Payment TXNID'},    
    ]

    const [allApplications, setAllApplications] = useState([]);
    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const getAllApplications = async () => {
            const res = await Axios.get(`${process.env.REACT_APP_API_URL}/all-applications`, {
                signal
            });
            // console.log(res.data.data)
            if (res.status === 404) {
                setAllApplications([])
            } else {
                setAllApplications(res.data.data)
            }
        }
        getAllApplications();
        return () => {
            abortController.abort();
        }
    }, []);



    return (
        <div className="manage-application">
            <div className="upper-btn">
                <div className="row">
                    <div className="col">
                        <button onClick={() => history.push('/dashboard')} className="btn btn-warning mb-2" type="button"><FaArrowCircleLeft /> Go To Home </button>

                    </div>
                </div>

                <div className="table-container table-responsive" >
                <ToolkitProvider
                    keyField="applicationSerial"
                    data={ allApplications }
                    columns={ columns }
                    search
                    exportCSV

                    >
                    {
                        props => (
                        <div>
                            <SearchBar { ...props.searchProps } />
                            <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton>

                            <BootstrapTable 
                                { ...props.baseProps }           
                                pagination={ paginationFactory() }
                                striped
                                wrapperClasses="table-responsive" 
                            />
                        </div>
                        )
                    }
                </ToolkitProvider>
                 </div>
            
            </div>
        </div>
    )
}

export default ManageApplications
